import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Builder from "./pages/index";
import ListView from "./pages/view";
import CreateView from "./pages/create";
import { CookieRequestComponent } from "@unity/components";


export default function Root(props) {
  
  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <CookieRequestComponent />

      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} /> 

                    {
                      // Example of an index route that is only accessible to users with the "active" and "read" jobs permissions
                    }
                    {props.auth.access["list-builder"]["A"] &&
                      props.auth.access["list-builder"]["R"] && (
                        <Route
                          path="/list-builder/index"
                          exact
                          component={(route) => (
                            <Builder
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                      {props.auth.access["list-builder"]["A"] && props.auth.access["list-builder"]["R"] && (
                          <Route
                            path="/list-builder/view" exact component={(route) => ( <ListView route={route} context={context} />
                            )}
                          />
                        )}
                      {props.auth.access["list-builder"]["A"] && props.auth.access["list-builder"]["R"] && (
                          <Route
                            path="/list-builder/create" exact component={(route) => ( <CreateView route={route} context={context} />
                            )}
                          />
                        )}

                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
