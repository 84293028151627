const DEFAULTBUTTONBACKGROUND = "rgba(20,0,200, 0.6)";
const DEFAULTBUTTONBACKGROUNDHOVER = "rgba(40,0,220, 0.3)";

const fullScreenContainer = () => ({
    //display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "80vh",
    alignItems: "center",
    justifyContent: "center"
});

const tableActionContainer = () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "0.5vw",
    rowGap: "0.5vh",
    margin: "0.5vh",
    width: "100%"
});

const glassButtonStyle = (theme) => ([
    {
        padding: "0.5vw",
        width: "80%",
        textAlign: "center",
        color: theme.top_menu.buttonFont ? theme.top_menu.buttonFont : "white",
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
    },
    {
        '&.MuiButton-contained': {
            backgroundColor: theme.top_menu.buttonBackground !== undefined ? theme.top_menu.buttonBackground : DEFAULTBUTTONBACKGROUND,
            backdropFilter: "blur(20px)",
        }
    },
    {
        '&.MuiButton-contained:hover': {
            backgroundColor: theme.top_menu.buttonBackground !== undefined ? theme.top_menu.buttonBackground : DEFAULTBUTTONBACKGROUNDHOVER,
        }
    }
]);

const buttonTitle = () => ({
     backgroundColor: "green", color: "white"
});

const selectStyle = (theme) => ([
    {
        borderColor: theme.top_menu.font,
        color: theme.top_menu.font,
        height: "100%"
    },
    {
        '&.MuiOutlinedInput-root': {
            borderColor: theme.top_menu.font,
            color: theme.top_menu.font,
            height: "100%",
            padding: "0.5vw"
        }
    }
]);

const optionStyle = () => ({
    color: "black", 
    margin: "1vw"
})

const headerTextField = (context) => ([
    {color: "pink", borderColor: "pink"},
    {'& input': {color: context.theme.top_menu.font, border: `solid 0.5px rgba(0,0,0,0.0)`, borderRadius: "5px", padding: "1vw" }},
    {'& label': {color: context.theme.top_menu.font}},
]);

const dataTableToolbar = (context) => ({
    backgroundColor: context.theme.top_menu.background,
    color: context.theme.top_menu.font,
    margin: "0px", 
    width: "100%",
    height: "100%",
    padding: "0.5vw",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "1vw",
    backdropFilter: "blur(20px)"
});

const dataTableFooter = () => ({ 
    padding: "0.5vw", 
    borderTop: "rgba(200,200,200,0.8) solid 0.5px", 
    display: "flex", 
    justifyContent: "flex-end", 
    alignItems: "center", 
    gap: "1vw", 
    margin: "0px" 
});

export {
    fullScreenContainer,
    tableActionContainer,
    glassButtonStyle,
    buttonTitle,
    selectStyle,
    optionStyle,
    headerTextField,
    dataTableToolbar,
    dataTableFooter
};