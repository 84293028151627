import React, { Suspense, useState } from "react";
import { fullScreenContainer } from "../styles/general";
import { AppBar, Tab, Tabs, Typography, Box, Fade, LinearProgress, IconButton } from "@mui/material";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { StorageManager } from "@unity/components";
const CreateList = React.lazy(() => import("../modules/CreateList"));

const LoadingComponent = ({component}) => (
    <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
        {component}
    </Suspense>
)


export default function CreateView({context}) {
    const [tab, setTab] = useState(0);

  // -------------------------------------------------------------------------------
  // This is just an example of how we are implementing session cache at the moment.
  // If you use make sure the module name is part of the key to avoid conflicts!
  const sm = new StorageManager();

  if(sm.checkGrant()) {
    let t = parseInt(sm.getItem("list-builder-index-tab", 0));
    if(!isNaN(t) && t !== tab) {
        setTab(t);
    }
  }

  const handleTabChange = (value) => {
    setTab(value);
    sm.setItem("list-builder-index-tab", value);
  }
  // -------------------------------------------------------------------------------
  const getTab = () => {
    switch(tab) {
        case 0: return <LoadingComponent component={<CreateList context={context}/>} />
        default: return (
            <Typography 
                variant="h5" 
                gutterBottom
            >
                Tab Not Found. Please Refresh.
            </Typography>
        )
    }
  }

  return (
    <>
        <Box
        style={{
            backgroundColor: "rgb(33, 0, 183)",
            height: "90px",
            padding: "2px",
            width:"100%",         
            top: 0,
        }}>
            <IconButton
                onClick={() => {window.location.pathname = `/list-builder/index`}}
                onTouchStart={() => {window.location.pathname = `/list-builder/index`}}
                style={{
                    paddingHorizontal: "16px",
                    paddingVertical: "8px",
                    borderRadius:"4px",
                    backgroundColor: "rgb(255, 255, 255)",
                    fontSize: "0.875rem",
                    height: "40px",
                    color:'#000000',
                    width: "64px",
                    top: "25px",
                    left: "10px"
                }}
            >
                <KeyboardBackspace />
            </IconButton>
            <Typography 
                variant="h6"
                style={{
                    color:'#ffffff',
                    marginLeft: "100px",
                    paddingTop: "0px",
                    marginTop: "-10px"
                }}>
                List Builder
            </Typography>
        </Box>
        <Box>
            <AppBar position="static">
                <Tabs
                    value={tab}
                    onChange={(e, val) => {handleTabChange(val)}}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    sx={[{
                        backgroundColor: '#fff',
                        color: '#212529'
                    }]}
                >
                    <Tab 
                        label="Create List"
                    />
                </Tabs>
            </AppBar>
        </Box>

        <div style={fullScreenContainer()}>
            { getTab() }
        </div>
    </>
  )
}
